import { Chip, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import CustomSelector from 'components/Common/CustomSelector';
import CustomSnackbar from 'components/Common/CustomSnackbar';
import DateFilter from 'components/Common/DateFilter';
import LinearIndeterminate from 'components/Common/LinearIndeterminate';
import DateFormat from 'components/DateFormat';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { PlotFacingTypeEnum } from 'pages/PropertyListing/types';
import React, { useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectButtonLoading,
  selectEndDate,
  selectList,
  selectLoading,
  selectOrderBy,
  selectOrderDir,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectStartDate,
  selectStatusFilter,
  selectTotalRow
} from './selector';
import { actions } from './slice';
import { IData, OrderStatusFilterEnum } from './types';
import UpdateOrderModal from './UpdateOrderModal';
import IsLoading from 'components/Common/IsLoading';

interface Props {
  isFromDashboard?: boolean;
}

function Index({ isFromDashboard }: Props) {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const loader = useSelector(selectButtonLoading);
  const search = useSelector(selectSearch);
  const pageNo = useSelector(selectPageNo);
  const pageSize = useSelector(selectPageSize);
  const TotalRow = useSelector(selectTotalRow);
  const data = useSelector(selectList);
  const orderBy = useSelector(selectOrderBy);
  const orderDir = useSelector(selectOrderDir);
  const statusFilter = useSelector(selectStatusFilter);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const [selectedId, setSelectedId] = React.useState<string | null>(null);

  const handleShowModal = (id: string) => {
    setSelectedId(id);
    dispatch(actions.toggleModalOpen(true));
    dispatch(
      actions.doGenerateUserOtp({
        id: id,
        callback() {}
      })
    );
  };

  useEffect(() => {
    if (isFromDashboard) {
      dispatch(actions.setStatusFilter(OrderStatusFilterEnum.PENDING));
    } else {
      dispatch(actions.setStatusFilter(OrderStatusFilterEnum.ALL));
    }
    return () => {};
  }, [isFromDashboard]);
  useEffect(() => {
    dispatch(actions.setPageNo(1));
    dispatch(actions.doGetList());
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(actions.doGetList());
    return () => {};
  }, [search, pageNo, pageSize, orderBy, orderDir, statusFilter]);

  const handleSearch = (e: any) => {
    dispatch(actions.setSearch(e.target.value));
  };
  const handleChangeStatus = (e: any) => {
    dispatch(actions.setStatusFilter(e.target.value));
  };

  const handleChange = (newValue: any) => {
    dispatch(actions.setStartDate(newValue));
  };
  const handleEndDateChange = (newValue: any) => {
    dispatch(actions.setEndDate(newValue));
  };
  const handleDate = () => {
    if (startDate === '') {
      CustomSnackbar('please enter start date', 'error');
      return;
    }
    if (endDate === '') {
      CustomSnackbar('please enter end date', 'error');

      return;
    }
    dispatch(actions.doGetList());
  };
  const handleShowMap = (lat: any, long: any) => {
    window.open(`https://www.google.com/maps?q=${long},${lat}`, '_blank');
  };

  const columns: TableColumn<IData>[] = [
    {
      name: 'Order No',
      selector: (row) => row.orderNo,
      sortable: false,
      sortField: 'orderNo',
      id: 'orderNo',
      width: '10rem',
      format: (row) => {
        return <p>{row?.orderNo}</p>;
      }
    },
    {
      name: 'Location',
      selector: (row) => row.address,
      sortable: true,
      wrap: true,
      sortField: 'address',
      id: 'address',
      minWidth: '10rem',
      format: (row) => {
        return (
          <p
            style={{ color: '#0047D0', cursor: 'pointer' }}
            onClick={() => handleShowMap(row?.location?.coordinates[0], row.location.coordinates[1])}
            className="threeLineText"
          >
            {row?.address || 'NA'}
          </p>
        );
      }
    },
    {
      name: 'Service Name',
      selector: (row) => row.serviceName,
      sortable: true,
      wrap: true,
      sortField: 'serviceName',
      id: 'serviceName',
      width: '10rem',
      format: (row) => {
        return <p>{row.serviceName || 'NA'}</p>;
      }
    },
    {
      name: 'User Name',
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      sortField: 'name',
      id: 'name',
      width: '10rem',
      format: (row) => {
        return <p>{row?.name || 'NA'}</p>;
      }
    },
    {
      name: 'User Email',
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
      sortField: 'email',
      id: 'email',
      width: '10rem',
      format: (row) => {
        return <p>{row?.email || 'NA'}</p>;
      }
    },
    {
      name: 'Dimensions',
      selector: (row) => row.plotLength,
      sortable: false,
      wrap: true,
      sortField: 'plotLength',
      id: 'plotLength',
      width: '10rem',
      format: (row) => {
        return (
          <p>
            {row.plotLength} {row.plotLength > 0 ? '*' : ''}
            {row.plotWidth}
            {row.plotLength == 0 && row.plotWidth == 0 ? 'NA' : ''}{' '}
          </p>
        );
      }
    },
    {
      name: ' Additional Info',
      selector: (row) => row.additionalInfo,
      sortable: true,
      wrap: true,
      sortField: 'additionalInfo',
      id: 'additionalInfo',
      width: '8rem',
      format: (row) => {
        return <p className="threeLineText">{row.additionalInfo || 'NA'}</p>;
      }
    },
    {
      name: 'Plot No',
      selector: (row) => row.plotNo,
      sortable: true,
      wrap: true,
      width: '10rem',
      sortField: 'plotNo',
      id: 'plotNo',
      format: (row) => {
        return <p>{row.plotNo || 'NA'}</p>;
      }
    },
    {
      name: 'Venture Name',
      selector: (row) => row.ventureName,
      sortable: true,
      wrap: true,
      width: '10rem',
      sortField: 'ventureName',
      id: 'ventureName',
      format: (row) => {
        return <p>{row?.ventureName || 'NA'}</p>;
      }
    },
    {
      name: 'Facing',
      selector: (row) => row.plotFacing,
      sortable: true,
      wrap: true,
      width: '10rem',
      sortField: 'plotFacing',
      id: 'plotFacing',
      format: (row) => {
        return (
          <p>
            {row?.plotFacing == PlotFacingTypeEnum.EAST
              ? 'East'
              : PlotFacingTypeEnum.NORTH
              ? 'North'
              : PlotFacingTypeEnum.SOUTH
              ? 'South'
              : PlotFacingTypeEnum.WEST
              ? 'West'
              : PlotFacingTypeEnum.NORTH_EAST
              ? 'North East'
              : PlotFacingTypeEnum.NORTH_WEST
              ? 'North West'
              : PlotFacingTypeEnum.SOUTH_EAST
              ? 'South East'
              : PlotFacingTypeEnum.SOUTH_WEST
              ? 'South West'
              : '-'}
          </p>
        );
      }
    },
    {
      name: 'Created At',
      selector: (row) => row.createdAt,
      sortable: true,
      wrap: true,
      sortField: 'createdAt',
      id: 'createdAt',
      width: '10rem',
      format: (row) => {
        return <p>{DateFormat(row.createdAt) || 'NA'}</p>;
      }
    },

    {
      name: 'Status',
      selector: (row) => row.status,
      format: (row) => {
        switch (row.status) {
          case 'Completed':
            return <Chip color="success" label="Completed" size="small" variant="light" />;
          case 'Pending':
            return <Chip color="warning" label="Pending" size="small" variant="light" />;
          case 'Failed':
            return <Chip color="error" label="Failed" size="small" variant="light" />;
        }
      }
    },
    {
      name: 'Actions',
      width: '10rem',
      selector: (row) => row._id,
      format: (row) => {
        return (
          <p style={{ display: 'flex' }}>
            {row.status !== 'Completed' && (
              <Tooltip title="Generate OTP">
                {loader ? (
                  <IsLoading />
                ) : (
                  <IconButton
                    disabled={loader}
                    color="primary"
                    onClick={() => {
                      handleShowModal(row?._id);
                    }}
                    style={{ width: '100%' }}
                  >
                    Generate OTP
                  </IconButton>
                )}
              </Tooltip>
            )}
          </p>
        );
      }
    }
  ];
  const handleClearDate = () => {
    dispatch(actions.setEndDate(null));
    dispatch(actions.setStartDate(null));
    dispatch(actions.doGetList());
  };
  return (
    <>
      <MainCard content={false} title={!isFromDashboard && 'List of Service Order'}>
        {!isFromDashboard && (
          <Grid xs={12} m={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <TextField fullWidth placeholder="Search" value={search} onChange={(e) => handleSearch(e)} />
              </Grid>
              <Grid item xs={12} sm={5} md={6}>
                <DateFilter
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDateChange={handleChange}
                  handleEndDateChange={handleEndDateChange}
                  handleAdd={() => handleDate()}
                  handleClear={handleClearDate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} justifyContent={'flex-end'} display={'flex'}>
                <CustomSelector
                  value={statusFilter}
                  onChange={handleChangeStatus}
                  name={'statusFilter'}
                  data={statusFilterData}
                  isShowLabel
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <ScrollX>
          <DataTable
            pagination={isFromDashboard ? false : true}
            columns={columns}
            data={data}
            fixedHeader
            progressPending={loading}
            responsive
            progressComponent={<LinearIndeterminate />}
            onChangePage={(pageNo: number, totalRows: number) => dispatch(actions.setPageNo(pageNo))}
            paginationServer
            paginationTotalRows={TotalRow}
            onChangeRowsPerPage={(currentRowsPerPage: number, currentPage: number) => {
              dispatch(actions.setPageSize(currentRowsPerPage));
            }}
            onSort={(selectedColumn, sortDirection, sortedRows) => {
              dispatch(actions.setOrderBy(selectedColumn.id?.toString() || ''));
              dispatch(actions.setOrderDir(sortDirection));
            }}
            sortServer={true}
          />
        </ScrollX>
      </MainCard>
      <UpdateOrderModal title={'Update Order'} content={'Upload Image to Close the Order'} selectedId={selectedId} />
    </>
  );
}

export default Index;
export const statusFilterData = [
  {
    id: OrderStatusFilterEnum.ALL,
    item: 'All'
  },
  {
    id: OrderStatusFilterEnum.PENDING,
    item: 'Pending'
  },
  {
    id: OrderStatusFilterEnum.COMPLETED,
    item: 'Complete'
  }
];
