import { PlotFacingTypeEnum, PlotTypeEnum, PropertyApprovalTypeEnum, PropertyAvailabilityStatusEnum } from 'pages/PropertyListing/types';
import { PlotSizeUnitTypeEnum } from 'pages/ServiceOrder/types';

export interface IData {
  _id: string;
  ticketId: string;
  name: string;
  address: string;
  location: {
    type: 'Point';
    coordinates: [0, 0];
  };
  plotSize: string;
  plotType: PlotTypeEnum;
  minimumBudget: number;
  maximumBudget: number;
  approvals: PropertyApprovalTypeEnum[];
  availabilityStatus: PropertyAvailabilityStatusEnum;
  plotFacing: PlotFacingTypeEnum;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  plotSizeUnit: PlotSizeUnitTypeEnum;
  paymentOption: PlotPaymentOptionEnum;
}

export enum PlotPaymentOptionEnum {
  NULL = 'Null',
  SPOT_PAYMENT = 'Spot Payment',
  FORTY_FIVE_DAYS = '45days',
  LOAN_OPTION = 'Loan Option'
}
export enum PlotApprovalTypeEnum {
  HMDA = 'HMDA',
  DTCP = 'DTCP',
  GP = 'GP',
  OTHER = 'OTHER'
}
export enum PlotStatusFilterEnum {
  ALL = 1,
  ACTIVE = 2,
  INACTIVE = 3
}

export interface PlotToBuyState {
  List: IData[];
  form: IData;
  loading: boolean;
  buttonLoading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  orderBy: string;
  orderDir: string;
  endDate: string;
  startDate: string;
  status: PlotStatusFilterEnum;
}

export type InitialState = PlotToBuyState;
