import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import CommonLayout from '../layout/CommonLayout';
import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import ServiceOrder from 'pages/ServiceOrder';
import Events from 'pages/Events';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics')));
const Settings = Loadable(lazy(() => import('../pages/Controls/Settings')));
const AppContactUS = Loadable(lazy(() => import('../pages/contact-us')));
const UserProfile = Loadable(lazy(() => import('../pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));
const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));
const Chat = Loadable(lazy(() => import('../pages/apps/chat'))); //chat

//Custom
const PropertyListing = Loadable(lazy(() => import('pages/PropertyListing')));
const PropertyForm = Loadable(lazy(() => import('pages/PropertyListing/Form')));
const ExcelUploadList = Loadable(lazy(() => import('pages/PropertyListing/ExcelUploadList')));
const ServiceCreation = Loadable(lazy(() => import('pages/ServiceCreation')));
const ServiceCreationForm = Loadable(lazy(() => import('pages/ServiceCreation/Form')));
const SupportList = Loadable(lazy(() => import('pages/Support')));
const ImagesUpload = Loadable(lazy(() => import('pages/ImagesUpload')));
const ServiceOrderDetails = Loadable(lazy(() => import('pages/ServiceOrder/Form')));
const PlotsForSale = Loadable(lazy(() => import('pages/PlotForSale')));
const PlotsForSaleDetails = Loadable(lazy(() => import('pages/PlotForSale/Form')));
const ConstructionLeads = Loadable(lazy(() => import('pages/ConstructionLead')));
const Blog = Loadable(lazy(() => import('pages/Blog')));
const BlogCreate = Loadable(lazy(() => import('pages/Blog/form')));
const PlotToBuy = Loadable(lazy(() => import('pages/PlotToBuy')));
const PlotToBuyDetails = Loadable(lazy(() => import('pages/PlotToBuy/form')));
const PropertyLeads = Loadable(lazy(() => import('pages/PropertyLeads')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <DashboardDefault />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },

        {
          path: 'controls',
          children: [
            {
              path: 'settings',
              element: <Settings />
            }
          ]
        },
        {
          path: 'property',
          children: [
            {
              path: 'list',
              element: <PropertyListing />
            },
            {
              path: 'form',
              element: <PropertyForm />
            },
            {
              path: 'form/:id',
              element: <PropertyForm />
            },
            {
              path: 'excel-list',
              element: <ExcelUploadList />
            },
            {
              path: 'excel-list/:id',
              element: <ExcelUploadList />
            }
          ]
        },
        {
          path: 'services',
          children: [
            {
              path: 'list',
              element: <ServiceCreation />
            },
            {
              path: 'form',
              element: <ServiceCreationForm />
            },
            {
              path: 'form/:id',
              element: <ServiceCreationForm />
            }
          ]
        },
        {
          path: 'service-order',
          children: [
            {
              path: '',
              element: <ServiceOrder isFromDashboard={false} />
            },
            {
              path: 'details',
              element: <ServiceOrderDetails />
            }
          ]
        },
        {
          path: 'plot-for-sale',
          children: [
            {
              path: '',
              element: <PlotsForSale />
            },
            {
              path: 'details',
              element: <PlotsForSaleDetails />
            }
          ]
        },
        {
          path: 'construction-leads',
          children: [
            {
              path: '',
              element: <ConstructionLeads />
            }
          ]
        },
        {
          path: 'events',
          children: [
            {
              path: '',
              element: <Events />
            }
          ]
        },
        {
          path: 'blog',
          children: [
            {
              path: '',
              element: <Blog />
            },
            {
              path: 'create',
              element: <BlogCreate />
            },
            {
              path: 'create/:id',
              element: <BlogCreate />
            }
          ]
        },
        {
          path: 'plot-to-buy',
          children: [
            {
              path: '',
              element: <PlotToBuy />
            },
            {
              path: 'details',
              element: <PlotToBuyDetails />
            },
            {
              path: 'details/:id',
              element: <PlotToBuyDetails />
            }
          ]
        },
        {
          path: 'support',
          children: [
            {
              path: '',
              element: <SupportList />
            }
          ]
        },
        {
          path: 'property-leads',
          children: [
            {
              path: '',
              element: <PropertyLeads />
            }
          ]
        },
        {
          path: 'image-upload',
          children: [
            {
              path: '',
              element: <ImagesUpload />
            }
          ]
        },
        {
          path: 'apps',
          children: [
            {
              path: 'profiles',
              children: [
                {
                  path: 'account',
                  element: <AccountProfile />,
                  children: [
                    {
                      path: 'basic',
                      element: <AccountTabProfile />
                    },
                    {
                      path: 'personal',
                      element: <AccountTabPersonal />
                    },
                    {
                      path: 'my-account',
                      element: <AccountTabAccount />
                    },
                    {
                      path: 'password',
                      element: <AccountTabPassword />
                    },
                    {
                      path: 'role',
                      element: <AccountTabRole />
                    },
                    {
                      path: 'settings',
                      element: <AccountTabSettings />
                    }
                  ]
                },
                {
                  path: 'user',
                  element: <UserProfile />,
                  children: [
                    {
                      path: 'personal',
                      element: <UserTabPersonal />
                    },
                    {
                      path: 'payment',
                      element: <UserTabPayment />
                    },
                    {
                      path: 'password',
                      element: <UserTabPassword />
                    },
                    {
                      path: 'settings',
                      element: <UserTabSettings />
                    }
                  ]
                }
              ]
            },
            {
              path: 'chat',
              element: <Chat />
            },
            {
              path: 'chat/:id/:mid',
              element: <Chat />
            }
          ]
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    }
  ]
};

export default MainRoutes;
