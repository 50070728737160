import { Grid, TextField } from '@mui/material';
import LinearIndeterminate from 'components/Common/LinearIndeterminate';
import DateFormat from 'components/DateFormat';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectList,
  selectLoading,
  selectOrderBy,
  selectOrderDir,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectStatusFilter,
  selectTotalRow
} from './selector';
import { actions } from './slice';
import { IData } from './types';

function Index() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const search = useSelector(selectSearch);
  const pageNo = useSelector(selectPageNo);
  const pageSize = useSelector(selectPageSize);
  const TotalRow = useSelector(selectTotalRow);
  const data = useSelector(selectList);
  const orderBy = useSelector(selectOrderBy);
  const orderDir = useSelector(selectOrderDir);
  const statusFilter = useSelector(selectStatusFilter);

  useEffect(() => {
    dispatch(actions.setPageNo(1));
    dispatch(actions.doGetList());
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(actions.doGetList());
    return () => {};
  }, [search, pageNo, pageSize, orderBy, orderDir, statusFilter]);

  const handleSearch = (e: any) => {
    dispatch(actions.setSearch(e.target.value));
  };

  const columns: TableColumn<IData>[] = [
    {
      name: 'S No.',
      selector: (row) => row._id,
      sortable: false,
      sortField: '_id',
      id: '_id',
      width: '10rem',
      format: (row, rowIndex) => rowIndex + 1,
      cell: function (row, rowindex) {
        if (pageNo === 1) {
          return <>{`${rowindex + 1}`}</>;
        } else {
          return <>{`${(pageNo - 1) * pageSize + rowindex + 1}`}</>;
        }
      }
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      sortField: 'name',
      id: 'name',
      minWidth: '10rem',
      format: (row) => {
        return <p>{row?.name || 'NA'}</p>;
      }
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
      sortField: 'title',
      id: 'title',
      width: '10rem',
      format: (row) => {
        return <p>{row?.title || 'NA'}</p>;
      }
    },
    {
      name: 'Date',
      selector: (row) => row.dateTime as string,
      sortable: true,
      wrap: true,
      sortField: 'dateTime',
      id: 'dateTime',
      width: '10rem',
      format: (row) => {
        return <p>{DateFormat(row.dateTime) || 'NA'}</p>;
      }
    },
    {
      name: 'Agenda',
      selector: (row) => row.agenda,
      sortable: true,
      wrap: true,
      sortField: 'agenda',
      id: 'agenda',
      width: '10rem',
      format: (row) => {
        return <p>{row?.agenda || 'NA'}</p>;
      }
    },
    {
      name: 'Details',
      selector: (row) => row.details,
      sortable: true,
      wrap: true,
      sortField: 'details',
      id: 'details',
      width: '10rem',
      format: (row) => {
        return <p className="threeLineText">{row?.details || 'NA'}</p>;
      }
    },
    {
      name: 'Location',
      selector: (row) => row.address,
      sortable: true,
      wrap: true,
      sortField: 'address',
      id: 'address',
      width: '10rem',
      format: (row) => {
        return (
          <p style={{ color: '#0047D0', cursor: 'pointer' }} className="threeLineText">
            {row?.address || 'NA'}
          </p>
        );
      }
    },

    {
      name: 'Link',
      selector: (row) => row.link,
      sortable: true,
      wrap: true,
      sortField: 'link',
      id: 'link',
      width: '10rem',
      format: (row) => {
        return <p>{row?.link || 'NA'}</p>;
      }
    },

    {
      name: 'Created At',
      selector: (row) => row.createdAt,
      sortable: true,
      wrap: true,
      sortField: 'createdAt',
      id: 'createdAt',
      width: '10rem',
      format: (row) => {
        return <p>{DateFormat(row.createdAt) || 'NA'}</p>;
      }
    }
  ];

  return (
    <>
      <MainCard content={false} title="List of Events">
        <Grid xs={12} m={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <TextField fullWidth placeholder="Search" value={search} onChange={(e) => handleSearch(e)} />
            </Grid>
          </Grid>
        </Grid>
        <ScrollX>
          <DataTable
            pagination
            columns={columns}
            data={data}
            fixedHeader
            progressPending={loading}
            responsive
            progressComponent={<LinearIndeterminate />}
            onChangePage={(pageNo: number, totalRows: number) => dispatch(actions.setPageNo(pageNo))}
            paginationServer
            paginationTotalRows={TotalRow}
            onChangeRowsPerPage={(currentRowsPerPage: number, currentPage: number) => {
              dispatch(actions.setPageSize(currentRowsPerPage));
            }}
            onSort={(selectedColumn, sortDirection, sortedRows) => {
              dispatch(actions.setOrderBy(selectedColumn.id?.toString() || ''));
              dispatch(actions.setOrderDir(sortDirection));
            }}
            sortServer={true}
          />
        </ScrollX>
      </MainCard>
    </>
  );
}

export default Index;
