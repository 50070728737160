import { PlotStatusFilterEnum } from 'pages/PlotToBuy/types';
import { EventState } from '.';

export const initialState: EventState = {
  List: [],
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: PlotStatusFilterEnum.ALL,
  endDate: '',
  startDate: ''
};
