import { AxiosResponse } from 'axios';
import CustomSnackbar from 'components/Common/CustomSnackbar';
import CatchError from 'hooks/CatchError';
import moment from 'moment';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { get, patch } from 'utilsNew/request';
import { selectEndDate, selectOrderBy, selectOrderDir, selectPageNo, selectPageSize, selectSearch, selectStartDate } from './selector';
import { actions } from './slice';

export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const sortColumn: string = yield select(selectOrderBy);
    const sortDir: string = yield select(selectOrderDir);
    const startDate: string = yield select(selectStartDate);
    const endDate: string = yield select(selectEndDate);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `event/list?pageNo=${pageNo}&pageSize=${pageSize}&statusFilter=1${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&orderBy=' + sortColumn : ''
      }${sortDir.length > 0 ? '&orderDirection=' + sortDir : ''}${startDate ? '&startDate=' + moment(startDate).format('YYYY-MM-DD') : ''}${
        endDate ? '&endDate=' + moment(endDate).format('YYYY-MM-DD') : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* doUpdateStatusRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(patch, `event/status/update/${action?.payload?.id}`, {});
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchError(error);
  }
}

export function* EventRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doChangeStatus, doUpdateStatusRequest);
}
